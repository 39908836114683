@use 'variables';
.page-news-single {

    &__content {
        p {
            font-size: 16px;
            line-height: 27px;
            margin: 20px 0px;
        }

        h2 {
            font-size: 22px;
            line-height: 32px;
            margin: 20px 0px;
            @include variables.for-desktop-up {
                font-size: 40px;
                line-height: 50px;
            }
        }

        h3 {
            margin: 20px 0px;
            font-size: 18px;
            line-height: 30px;
            @include variables.for-desktop-up {
                font-size: 30px;
                line-height: 40px;
            }
        }

        strong {
            @include variables.bold-font;
        }

        table {
            border-collapse: collapse;
            border-spacing: 0;
            max-width: 56rem;
            border: 1px solid #ddd;
            line-height: 27px;
            @include variables.regular-font;
        }

        em {
            font-style: italic;
        }

        th,
        td {
            text-align: left;
            padding: 10px;
        }

        tr:nth-child(even) {
            background-color: #f2f2f2;
        }

        ul {
            margin-bottom: 20px;
            margin-left: 15px;
            @include variables.regular-font;
            li {
                font-size: 16px;
                list-style: none;
                line-height: 1.688rem;
                margin-bottom: 20px;
                padding-left: 35px;
                position: relative;
                &::before {
                    position: absolute;
                    background-color: #000;
                    border-radius: 2px;
                    border: 2px solid #000;
                    content: '';
                    display: inline-block;
                    margin-right: 15px;
                    vertical-align: middle;
                    left: 0;
                    top: 10px;
                    padding-left: 17px;
                }
            }
        }

        a {
            color: #00A053;
            @include variables.bold-font;
            &:hover {
                text-decoration: underline;
            }
        }

        iframe {
            width: 100%;
            max-height: 28rem;
            height: calc(100vw * 0.5);
        }
    }
}

